import { ApiCall } from "../apiCall";

export async function deleteJournals(
    venueId: string,
    fromDate: string,
    toDate: string
) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "JOURNALS",
        "delete",
        "DELETE",
        {},
        `?venue_id=${venueId}&from_date=${fromDate}&to_date=${toDate}`
    );
}
